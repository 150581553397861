import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { injectIntl, FormattedMessage } from "gatsby-plugin-react-intl";
import ProjectTemplate from "./projectTemplate";

const IndexPage = ({intl, pageContext}) => {
  return(
    <Layout>
      <SEO title={pageContext.projectInfo.title} lang={intl.locale}/>
      <ProjectTemplate pageContext={pageContext}></ProjectTemplate>
    </Layout>
    )
}

export default injectIntl(IndexPage)