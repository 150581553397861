import React, { useContext, useRef} from "react";
import LazyLoad from 'react-lazyload';
import ReactPlayer from "react-player/lazy";
//import { Link } from "gatsby";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import ReactLogo from "../static/lchtgst_logo_only_black_paths.svg";
import {Helmet} from "react-helmet";

import { useRecoilState } from "recoil"
import { filterState } from "./atoms/filter"
import { darkModeState } from "./atoms/darkMode";

import { Link, useIntl } from "gatsby-plugin-react-intl";

import { useMediaQuery } from 'react-responsive';
import Logo from "../static/lchtgst_logo_only_black_paths.svg";

const ProjectTemplate = ({ pageContext }) => {

  const intl = useIntl();
  const projectInfo = pageContext.projectInfo;
  //console.log(projectInfo);

  const pageEnd = useRef(null);

  const srcSetSizeXS = 480;
  const srcSetSizeS = 600;
  const srcSetSizeM = 960;
  const srcSetSizeL = 1400;
  const srcSetSizeXL = 1920;
  const srcSetSizeXXL = 2560;

  //media query to select vw proportion for aspect correct responsive video
  const videoFullWidth = useMediaQuery({ maxWidth: 990 });
 
  //const projectInfoFiltered = workContent.filter((project) => project.slug === slugTitle);
  //escape error for typos in manually typed project slug which result in an attempt to reduce empty array -> redirect to home
  //const projectInfo = projectInfoFiltered.length >= 1 ? projectInfoFiltered.reduce((prev, curr) => {return null;}) : window.location.href = '/';
  //const projectInfo = projectInfoFiltered.reduce((prev, curr) => {return null;});

  const [currentFilter, setFilter] = useRecoilState(filterState);
  const [ currentDarkMode ] = useRecoilState(darkModeState);
  const darkMode = currentDarkMode;

  const isBrowser = typeof window !== `undefined`;

  const scrollWithOffset = (el) => {
    if (isBrowser) {
      const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
      const yOffset = -54;
      window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
    }
  };

  //context Aware Toggle for Credits
  const ContextAwareToggle = ({ children, eventKey, callback }) => {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <button
        style={{ border: "none", padding: "0" }}
        onClick={() => {decoratedOnClick(); setTimeout(function(){pageEnd.current.scrollIntoView({ behavior: "smooth", block: "end" });}, 325);}}
        className="projectCreditsToggle"
      >
        <div className="creditsLogoContainer">
          <ReactLogo
            //className="aboutReferencesLogo logo-clicked"
            className={`projectCreditsLogo ${
              isCurrentEventKey ? "logo-clicked" : "logo-notClicked"
            }`}
            //style={{backgroundColor: isCurrentEventKey ? 'wheat' : 'white'}}
          />
        </div>
        {children}
      </button>
    );
  };

  return (
    <div className="project h-100">
      <Helmet>
          <title>{"lichtgestalten | " + `${projectInfo.title}`}</title>
          {intl.locale == "en" ? <meta name="description" content={projectInfo.descriptionEn} /> 
          : intl.locale == "de" ? <meta name="description" content={projectInfo.descriptionDe} /> 
          : <meta name="description" content={projectInfo.descriptionEn} />}
          <meta property="og:url" content={"https://lichtgestalten.li/projects/" + `${projectInfo.slug}`} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={projectInfo.imageURL} />
          <html lang={intl.locale} />
      </Helmet>
      <div className="row h-100 no-gutters">
        <div className="col-lg-8 col-sm-24 projectText">
          <Link
                className="projectTitleHaderClose"
                to={`/`}
              >
            <div className="projectTitleLogoLg">
              <Logo className="projectTitleLogo" />
              {/*<img className="projectTitleLogo" src={logo} alt="Lichtgestalten Logo" />*/}
            </div>
            <div className="projectTitleHeader">
              <h1>{projectInfo.title}</h1>
              {/*scroll back to project thumbnail on main page*/}     
            </div>
            <span className="projectTitleHaderCloseCross">{'\u2715'}</span> 
          </Link>
          <p className="projectDescription">
          {intl.locale == "en" ? projectInfo.descriptionEn 
          : intl.locale == "de" ? projectInfo.descriptionDe 
          : projectInfo.descriptionEn}
          </p>
          <div className="projectCredits mt-auto">
            <Accordion>
              <Card>
                <Card.Header>
                  <ContextAwareToggle eventKey="0">
                    <h2 className="creditsH2Card">Credits</h2>
                  </ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <div>
                      {projectInfo.info.length > 0 &&
                        projectInfo.info.map((infoEntry, index) => {
                          //function to test for "#" string indication, return <p><a href=</p> in that case, otherwise return <p></p>
                          //<p><a href="http://wiki.selfhtml.org/wiki/href"/>Test</p> or <p>Test</p>
                          const formatInfo = (stringArray) => {
                            let newFormat = [];
                            stringArray.map((text, index) => {
                              //case 1: return url <span><a></a></span>
                              if (text.includes("#")) {
                                let urlPos = text.indexOf("#");
                                newFormat.push(
                                  <span key={index}>
                                    <a
                                      className="projectCreditsA"
                                      href={text.slice(urlPos + 1)}
                                      target="_blank"
                                      rel="noopener noreferrer"

                                    >
                                      {text.slice(0, urlPos-1)}
                                    </a>
                                  </span>
                                );
                                return null;
                                //case 2: return only text <span></span>
                              } else {
                                return newFormat.push(
                                  <span key={index}>{text}</span>
                                );
                              }
                            });
                            //return final Array
                            return newFormat;
                          };

                          return (
                            <p className="projectCreditsP" key={index}>
                              {intl.locale == "en" ? infoEntry.slugCreditsEn 
                              : intl.locale == "de" ? infoEntry.slugCreditsDe 
                              : infoEntry.slugCreditsEn}:{" "}
                              {formatInfo(
                                infoEntry.values
                              ).reduce((prev, curr) => [prev, ", ", curr])}
                            </p>
                          );
                        })}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <div ref={pageEnd} />
          </div>
        </div>
        <div></div>
        <div className="col-lg-16 col-sm-24 projectVGallery">
          <div>
            {projectInfo.galleryContent.length > 0 &&
              projectInfo.galleryContent.map((galleryItem, index) => {
                if (galleryItem.type === "video") {
                  return (
                      <ReactPlayer
                        key = {index}
                        className="aspectVideo"
                        url={galleryItem.url}
                        controls={true}
                        light={true}
                        playing={true}
                        config={{
                          vimeo: {
                            playerVars: { title: "true" },
                          },
                        }}
                        style={videoFullWidth ? {"minHeight" : ("calc(100vw/" + galleryItem.aspect + ")")} : {"minHeight" : ("calc(66.66vw/" + galleryItem.aspect + ")")}}
                      />
                  );
                } else if (galleryItem.type === "image") {
                  return (
                    <div className="row no-gutter projectImageWrapper" key = {index}>
                      <img
                        className="img-fluid projectImage"
                        //src={`${galleryItem.url.substr(0, galleryItem.url.lastIndexOf("."))}` + '_' + `${srcSetSizeM}` + `${galleryItem.url.substr(galleryItem.url.lastIndexOf("."))}`}
                        sizes="(max-width: 996px) 100vw, 67vw"
                        width="1280"
                        height="720"
                        src={
                            `${galleryItem.url.substr(0, galleryItem.url.lastIndexOf("."))}` + '_' + `${srcSetSizeXS}` + `${galleryItem.url.substr(galleryItem.url.lastIndexOf("."))}`
                        }
                        srcSet={
                          `${galleryItem.url.substr(0, galleryItem.url.lastIndexOf("."))}` + '_' + `${srcSetSizeXXL}` + `${galleryItem.url.substr(galleryItem.url.lastIndexOf("."))}` + ' ' + `${srcSetSizeXXL}` + "w, " +
                          `${galleryItem.url.substr(0, galleryItem.url.lastIndexOf("."))}` + '_' + `${srcSetSizeXL}` + `${galleryItem.url.substr(galleryItem.url.lastIndexOf("."))}` + ' ' + `${srcSetSizeXL}` + "w, " +
                          `${galleryItem.url.substr(0, galleryItem.url.lastIndexOf("."))}` + '_' + `${srcSetSizeL}` + `${galleryItem.url.substr(galleryItem.url.lastIndexOf("."))}` + ' ' + `${srcSetSizeL}` + "w, " +
                          `${galleryItem.url.substr(0, galleryItem.url.lastIndexOf("."))}` + '_' + `${srcSetSizeM}` + `${galleryItem.url.substr(galleryItem.url.lastIndexOf("."))}` + ' ' + `${srcSetSizeM}` + "w, " +
                          `${galleryItem.url.substr(0, galleryItem.url.lastIndexOf("."))}` + '_' + `${srcSetSizeS}` + `${galleryItem.url.substr(galleryItem.url.lastIndexOf("."))}` + ' ' + `${srcSetSizeS}` + "w, " +
                          `${galleryItem.url.substr(0, galleryItem.url.lastIndexOf("."))}` + '_' + `${srcSetSizeXS}` + `${galleryItem.url.substr(galleryItem.url.lastIndexOf("."))}` + ' ' + `${srcSetSizeXS}` + "w"
                        }
                        alt={intl.locale === "de" ? galleryItem.altDe : galleryItem.altEn}
                      ></img>
                    </div>
                  );
                } else if (galleryItem.type === "loop") {
                  if (videoFullWidth) { //if video is shown in full width, scroll container is not necessary
                    return (
                      <LazyLoad classNamePrefix={"lazyload-videoLoop"} height={720} offset={100} once key={index}>
                        <video muted playsInline autoPlay loop className="aspectVideoLoop row no-gutter" key={index}>
                          <source src={galleryItem.url} type="video/mp4" alt={galleryItem.alt}/>
                        </video>
                      </LazyLoad>
                    );
                  } else {
                    return (
                      <LazyLoad classNamePrefix={"lazyload-videoLoop"} height={720} offset={300} once scrollContainer={".projectVGallery"} key={index}>
                        <video muted playsInline autoPlay loop className="aspectVideoLoop row no-gutter" key={index}>
                          <source src={galleryItem.url} type="video/mp4" alt={galleryItem.alt}/>
                        </video>
                      </LazyLoad>
                    )
                  }
                }
                return null;
              })}
          </div>
          {/*
          <div className="row no-gutter">
            <img
              className="img-fluid projectImage"
              src="https://lchtgst.diphda.uberspace.de/wp-content/uploads/2019/04/lichtgestalten-ritter-sport-kakao-klasse-waterfall-gif.gif"
              alt={projectInfo.title}
            ></img>
          </div>
          <div className="row no-gutter">
            <img
              className="img-fluid projectImage"
              src="https://lchtgst.diphda.uberspace.de/wp-content/uploads/2019/04/lichtgestalten-ritter-sport-kakao-klassse_1.png"
              alt={projectInfo.title}
            ></img>
          </div>
          <div className="row no-gutter">
            <img
              className="img-fluid projectImage"
              src="https://lchtgst.diphda.uberspace.de/wp-content/uploads/2019/04/lichtgestalten-ritter-sport-kakao-klassse_2.png"
              alt={projectInfo.title}
            ></img>
          </div>
          */}
        </div>
      </div>

      <footer className="fixed-bottom">
        <div className="container-fluid">
          <ul className="list-group list-group-horizontal">
            <li className="list-group-item work-category flex-fill">
              <Link
              className="projectTitleHaderClose"
              to = "/"
              onClick={() => setFilter("artDirection")}
              //scroll={(el) => scrollWithOffset(el)}
              >
                <div
                  className="footer-item"
                  style={
                    projectInfo.tags.includes("artDirection")
                      ? darkMode
                        ? { color: "#000", backgroundColor: "#fff" }
                        : { color: "#fff", backgroundColor: "#000" }
                      : //darkMode.value ? console.log("true") : console.log("flase")
                        null
                  }
                >
                  art direction
                </div>
              </Link>
            </li>
            <li className="list-group-item work-category flex-fill">
              <Link
              className="projectTitleHaderClose"
              to = "/"
              onClick={() => setFilter("animation")}
              //scroll={(el) => scrollWithOffset(el)}
              >
                <div
                  className="footer-item"
                  style={
                    projectInfo.tags.includes("animation")
                      ? darkMode
                        ? { color: "#000", backgroundColor: "#fff" }
                        : { color: "#fff", backgroundColor: "#000" }
                      : null
                  }
                >
                  animation
                </div>
              </Link>
            </li>
            <li className="list-group-item work-category flex-fill">
              <Link
              className="projectTitleHaderClose"
              to = "/"
              onClick={() => setFilter("mapping")}
              //scroll={(el) => scrollWithOffset(el)}
              >
                <div
                  className="footer-item"
                  style={
                    projectInfo.tags.includes("mapping")
                      ? darkMode
                        ? { color: "#000", backgroundColor: "#fff" }
                        : { color: "#fff", backgroundColor: "#000" }
                      : null
                  }
                >
                  mapping
                </div>
              </Link>
            </li>
            <li className="list-group-item work-category flex-fill">
              <Link
              className="projectTitleHaderClose"
              to = "/"
              onClick={() => setFilter("realtime")}
              //scroll={(el) => scrollWithOffset(el)}
              >
                <div
                  className="footer-item"
                  style={
                    projectInfo.tags.includes("realtime")
                      ? darkMode
                        ? { color: "#000", backgroundColor: "#fff" }
                        : { color: "#fff", backgroundColor: "#000" }
                      : null
                  }
                >
                  realtime
                </div>
              </Link>
            </li>
            <li className="list-group-item work-category flex-fill">
              <Link
              className="projectTitleHaderClose"
              to = "/"
              onClick={() => setFilter("installation")}
              //scroll={(el) => scrollWithOffset(el)}
              >
                <div
                  className="footer-item"
                  style={
                    projectInfo.tags.includes("installation")
                      ? darkMode
                        ? { color: "#000", backgroundColor: "#fff" }
                        : { color: "#fff", backgroundColor: "#000" }
                      : null
                  }
                >
                  installation
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default ProjectTemplate;
